<template>
    <div class="_phoneMenu" v-if="!seen">
        <div class="_li" v-for="(item, index) in menu" :key="index" :class="{ p_active: p_current == index }"
            @click="goRout(item,index)">
            {{ item.text }}
        </div>

    </div>
</template>

<script>
export default {
    data() {
        return {
            seen: true,
        }
    },
    props: {
        menu: {
            type: Array,
        },
        p_current: {
            type: Number
        }
    },
    mounted() {
        this.menuSeen()
    },
    methods: {
        menuSeen() {
            // 屏幕尺寸
            let screenHeight = document.body.clientWidth;
            if (screenHeight > 415) {
                this.seen = true;
            } else {
                this.seen = false;
            }
        },
        goRout(item,index) {
            this.$emit("changeRoute",index)
            this.$router.push({
                path: item.path, query: {
                    tit: item.text
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
._phoneMenu
{


    display: flex;
    width: 100%;
    margin-top: 60px;
    background-color: #3D4044;
    height: 50px;
    justify-content: space-around;
    align-items: center;
    color: white;

    .p_active
    {
        color: #F9DAA0;
    }
}
</style>
<template>
  <div class="_t_wrap">

    <phoneMenu :menu="p_menu" :p_current="1"></phoneMenu>


    <div class="_t_auto">
      <h4></h4>
      <div class="_list">
        <div class="_li" v-for="(item, index) in list" :key="index" @click="goDetail(item)">
          <div class="_img">
            <img :src="$IMG_URL + item.cover" alt="">
          </div>

          <div class="_txt">
            <div class="_txt_t">
              <div class="_h4">{{ item.title }}</div>
              <div class="_time">
                <span>{{ item.release_date }}</span>
                <span class="_shu"></span>
                <img src="@/assets/images/yj.png" alt="">
                <span>{{ item.hits }}</span>
              </div>


            </div>
          </div>

          <div class="_p">
            {{ item.description }}
          </div>

        </div>
      </div>


      <div class="_page">
        <pagination v-show="total > 0" :total="total" :page.sync="queryParams.page" :limit.sync="queryParams.page_num"
          @pagination="getList" />
      </div>
    </div>


    <fixIcon />

    <coment ref="comentRef"></coment>
    <mainFooter ref="mainFooterRef"></mainFooter>
  </div>
</template>

<script>
import { getDataById, hits } from '../../api/getData'
import phoneMenu from '../../components/phoneMenu/phoneMenu.vue';

export default {
  components: {
    phoneMenu
  },
  data() {
    return {
      list: [],
      total: 0,
      queryParams: {
        page: 1,
        page_num: 8,
      },
      p_menu: [
        {
          text: "社教活动",
          path: "/takeCare",
        }, {
          text: "青少年教育",
          path: "/qsnEdu",
        }
      ]
    }
  },
  mounted() {
    this.getList()
    this.$scrollTop()
  },
  methods: {
    getList() {
      const params = {
        cate_id: 24,
        page: this.queryParams.page,
        page_num: this.queryParams.page_num,
        keywords: "",
        type_id: "",
        times: "",
      }
      getDataById(params).then(res => {
        console.log(res)
        this.list = res.data.data
        this.total = res.data.total
      })
    },
    goDetail(item) {
      let url = window.origin
      if (item.third_link != null && item.third_link != '') {
        const params = {
          id: item.id
        }
        hits(params)
        window.open(item.third_link)
      } else {
        window.open(`${url}/studyDetail?tit=青少年教育&id=${item.id}`)

      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/common/mixin.scss";

._page
{
  text-align: center;
}

@mixin hidden
{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin tit($url)
{
  width: 299px;
  height: 52px;
  background-image: url($url);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

._t_wrap
{
  width: 100%;
  background-color: #F8F5EE;
  overflow: hidden;
}

._t_auto
{
  width: 85%;
  margin: 0 auto;
  margin-top: 200px;

  h4
  {
    @include tit("../../assets/images/h4-14.png");
    margin: 0 auto;
    margin-bottom: 40px;
  }
}


._list
{
  display: flex;
  flex-wrap: wrap;

  ._li
  {
    width: 23.28%;
    margin-right: 2.2%;
    cursor: pointer;
    margin-bottom: 65px;

    ._img
    {
      width: 100%;
      height: 237px;
      overflow: hidden;

      img
      {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all .5s;
      }

      img:hover
      {
        transform: scale(1.1);
      }
    }

    ._txt
    {
      margin-top: 20px;
      // padding: 12px 0;
      // border-bottom: 1px solid #ccc;

      ._txt_t
      {
        ._h4
        {
          font-size: 24px;
          margin-bottom: 8px;
          @include hidden();
        }

        ._time
        {
          display: flex;
          align-items: center;
          color: #666;

          ._shu
          {
            display: inline-block;
            width: 1px;
            height: 12px;
            background-color: #ccc;
            margin: 0 16px;
          }
        }

      }


    }

    ._p
    {
      width: 100%;
      color: #666;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      /* 设置最大显示行数 */
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      margin-top: 7px;
    }


    &:nth-child(4n)
    {
      margin-right: 0;
    }
  }
}


._t_auto
{
  @include respondTo("phone")
  {
    width: 95%;
    margin-top: 20px;
    margin-bottom: 30px;

    >h4
    {
      background-image: url("../../assets/images/phone/qsnjy.png");
      width: 173px;
      height: 30px;
      margin-bottom: 20px;
    }

    ._list
    {
      display: block;

      ._li
      {
        width: 100%;
        margin-right: 0;
        margin-bottom: 22px;
      }
    }
  }
}
</style>